import React      from "react"
import Layout     from "../components/layout"
import Booking    from '../components/booking';
import ParaHead   from "../components/para_head"
//https://www.markus-steinacher.at/sichere-externe-links-durch-target_blank-relnoopener-noreferrer/

const TXT2 = <p>
							Preise für 1 bis 2 Personen:<br />
							- Ab 3 Tage: 35€/Tag<br />
							<b>Zahlungsmöglichkeiten</b><br />
							Barzahlung<br /></p>
const TXT3 = <p>
							<b>Mietbedingungen</b><br />
							Gesamtzahlung bei Anreise<br />
							keine Kaution<br />
							Anreisetag: Täglich - früheste Anreisezeit: 13:00 Uhr<br />
							Abreise: bis spätestens 10:00 Uhr<br /><br />
						  Wohnzimmer, Küche, Schlafzimmer und Bad (siehe Bilder)<br />
						  Waschmaschine zur Mitbenutzung.<br />
							Großer Garten.<br />
							Rauchen und Mitbringen von Tieren nicht erwünscht.<br />
							Incl. Bettwäsche, Handtücher und Endreinigung.<br />
							Es wird keine Kurtaxe erhoben.<br />
							Telefon, Internetanschluß, Heizung, Kurtaxe, alles inkl.</p>

const Buchung = () => (
	<Layout head_gif = "buchung_bhb.png">
		<ParaHead txt="Kalender" />
		<h4>(Kalender wird aktualisiert...bitte Geduld)</h4>
		<a href="https://www.traum-ferienwohnungen.de/17040.htm#kalender" target="_blank" rel="noopener noreferrer">
				<button type="button" className="btn btn-outline-danger">Zur Buchung</button>
		 </a>&nbsp;&nbsp;&nbsp;
		 <a href="mailto:fmeierhoff@gmx.de">
		   <button type="button" className="btn btn-outline-danger">Anfrage per Mail</button>
		 </a>
		 <p align="center"><Booking /></p><br/>
		 <ParaHead txt="Preise" />
	   {TXT2}
		 <ParaHead txt="Beschreibung" />
 		 {TXT3}
	</Layout>
);
export default Buchung;
