import React, { Component } from 'react'
//Script-Fehler: Nach Klick "Reisemonat wählen" verschwindet die Kombobox
//Erscheint erst nach temp Wechsel auf eine andere Seite oder Reload

class Booking extends Component {
  componentDidMount(){
      const script=document.createElement('script')
      script.src="https://www.traum-ferienwohnungen.de/widgets/boot.js"
      script.async=true;
      this.instance.appendChild(script)
  }
  render() {
    return (
      <div ref={el => (this.instance = el)} className="vagaro">
       <a className="traumfewo-calendar" href="https://www.traum-ferienwohnungen.de/17040.htm#kalender"
           title="Ferienwohnung am alten Siel, direkt am Jadebusen bei Traum-Ferienwohnungen.de"
           data-listing="17040" data-language="de" data-months="10" data-columns="6">
           <img src="" alt=""/>
        </a>
      </div>
    )
  }
}
export default Booking
